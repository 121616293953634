<div class="card" (dblclick)="emitNavigate()" (mousedown)="preventSelection($event)" [title]="assetFolder.folderName | sqxTranslate">
    <div class="card-body">
        <div class="row g-2">
            <div class="col-auto">
                <img src="./images/folder.svg">
            </div>
            <div class="col truncate">
                {{assetFolder.folderName | sqxTranslate}}
            </div>
            <div class="col-auto">
                <ng-container *ngIf="canDelete || canUpdate">
                    <button type="button" class="btn btn-sm btn-text-secondary" (click)="dropdown.toggle()" #buttonOptions>
                        <i class="icon-dots"></i>
                    </button>
    
                    <ng-container *sqxModal="dropdown;closeAlways:true">
                        <sqx-dropdown-menu [sqxAnchoredTo]="buttonOptions" [scrollY]="true">  
                            <ng-container *ngIf="canUpdate">
                                <a class="dropdown-item" (click)="editDialog.show()">
                                    {{ 'common.rename' | sqxTranslate }}
                                </a>
                        
                                <div class="dropdown-divider"></div>
                            </ng-container>
    
                            <a class="dropdown-item dropdown-item-delete" [class.disabled]="!canDelete"
                                (sqxConfirmClick)="emitDelete()"
                                confirmTitle="i18n:assets.deleteFolderConfirmTitle"
                                confirmText="i18n:assets.deleteFolderConfirmText"
                                confirmRememberKey="deleteAssetFolder">
                                {{ 'common.delete' | sqxTranslate }}
                            </a>
                        </sqx-dropdown-menu>
                    </ng-container>
                </ng-container>
            </div>
        </div>            
    </div>
</div>


<ng-container *sqxModal="editDialog">
    <sqx-asset-folder-dialog [assetFolder]="assetFolder"
        (complete)="editDialog.hide()">
    </sqx-asset-folder-dialog>
</ng-container>