<ng-container *ngIf="users | async; let currentUsers">
    <ng-container *ngIf="currentUsers.length > 1">
        <div class="user-item" *ngFor="let userId of currentUsers | slice:0:5; let i = index" [style]="{ zIndex: i }">
            <img class="user-picture" title="{{userId | sqxUserName}}" [src]="userId | sqxUserPicture">
        </div>
    
        <div class="user-item" *ngIf="currentUsers.length > 5" [style]="{ zIndex: 6 }">
            <div class="user-more">+{{currentUsers.length - 5}}</div>
        </div>
    </ng-container>
</ng-container>