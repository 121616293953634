<div #container class="drop-container"
    (sqxDropFile)="insertFiles($event)"
    [sqxDropOnlyImages]="true">
    <div #inner [class.fullscreen]="snapshot.isFullscreen">
        <textarea class="form-control editor" #editor></textarea>
    </div>
</div>

<ng-container *sqxModal="assetsDialog">
    <sqx-assets-selector
        (select)="insertAssets($event)">
    </sqx-assets-selector>
</ng-container>

<ng-container *sqxModal="contentsDialog">
    <sqx-content-selector
        [language]="language"
        [languages]="languages"
        [schemaIds]="schemaIds"
        (select)="insertContents($event)">
    </sqx-content-selector>
</ng-container>