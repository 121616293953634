<ng-container *ngIf="path">
    <ng-container *ngIf="path.length === 0; else normalPath">
        <span class="btn">{{ 'common.searchResults' | sqxTranslate }}</span>
    </ng-container>
    <ng-template #normalPath>
        <ng-container *ngFor="let item of path; let i = index">
            <i class="icon-angle-right" *ngIf="i > 0"></i>
    
            <a class="btn" (click)="navigate.emit(item)" [class.force]="i < path.length - 1 || all" [class.first]="i === 0">
                {{item.folderName | sqxTranslate}}
            </a>
        </ng-container>
    </ng-template>
</ng-container>