<ul class="nav navbar-nav align-items-center">
    <li class="nav-item nav-icon dropdown" #button>
        <span class="nav-link dropdown-toggle" (click)="toggleProfile()">
            <span class="user">
                <img class="user-picture" [src]="snapshot.profileId | sqxUserIdPicture">
            </span>
        </span>
    </li>
</ul>

<ng-container *sqxModal="modalMenu;onRoot:false;closeAlways:true">
    <sqx-dropdown-menu [sqxAnchoredTo]="button" [offsetY]="10">
        <a class="dropdown-item dropdown-info" href="{{snapshot.profileUrl}}" sqxExternalLink="noicon">
            <div>{{ 'profile.userEmail' | sqxTranslate }}</div>
    
            <strong>{{snapshot.profileEmail}}</strong>
        </a>
        
        <div class="dropdown-divider"></div>

        <a class="dropdown-item" routerLink="/app/administration" *ngIf="uiState.canUserAdminResource | async">
            {{ 'common.administration' | sqxTranslate }}
        </a>

        <a class="dropdown-item" href="{{snapshot.profileUrl}}" sqxExternalLink="noicon">
            {{ 'profile.title' | sqxTranslate }}
        </a>

        <div class="dropdown-submenu">
            <a class="dropdown-item dropdown-toggle" (click)="toggleSubmenu()" sqxStopClick>
                {{ 'common.language' | sqxTranslate }}
            </a>

            <div class="dropdown-menu" [class.open]="snapshot.showSubmenu">
                <a class="dropdown-item" *ngFor="let availableLanguage of languages" [class.active]="availableLanguage.iso2Code === language" (click)="changeLanguage(availableLanguage.iso2Code)">
                    {{ availableLanguage.localName }}
                </a>
            </div>
          </div>

        <div class="dropdown-divider"></div>

        <a class="dropdown-item" (click)="logout()" sqxExternalLink>
            {{ 'common.logout' | sqxTranslate }}
        </a>
    </sqx-dropdown-menu>
</ng-container>