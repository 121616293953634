<div #container (sqxResized)="updateContainerWidth($event.width)">
    <div class="form-check" *ngFor="let value of tagValues; trackBy: trackByValue"
        [class.form-check-block]="!snapshot.isSingleline"
        [class.form-check-inline]="snapshot.isSingleline">
        <input class="form-check-input" type="radio" id="{{controlId}}_{{value}}" 
            [disabled]="snapshot.isDisabled"
            [ngModel]="valueModel"
            (ngModelChange)="callChange($event)"
            [value]="value.value"
        />

        <label class="form-check-label" for="{{controlId}}_{{value}}">{{value.name}}</label>
    </div>
</div>