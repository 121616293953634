<ng-content></ng-content>

<ng-container *sqxModal="dialogView">
    <sqx-modal-dialog [showClose]="false" (close)="cancel()" *ngIf="snapshot.dialogRequest; let request">
        <ng-container title>
            {{request.title | sqxTranslate}}
        </ng-container>
    
        <ng-container content>
            <span [innerHTML]="request.text | sqxTranslate | sqxMarkdown | sqxSafeHtml"></span>

            <div class="form-check mt-4" *ngIf="request.canRemember">
                <input class="form-check-input" type="checkbox" id="remember" [(ngModel)]="request.remember">

                <label class="form-check-label" for="remember">
                    {{ 'common.remember' | sqxTranslate}}
                </label>
            </div>
        </ng-container>
    
        <ng-container footer>
            <button type="button" class="btn btn-text-secondary" (click)="cancel()">
                {{ 'common.no' | sqxTranslate }}
            </button>

            <button type="button" class="btn btn-danger" (click)="confirm()" sqxFocusOnInit>
                {{ 'common.yes' | sqxTranslate }}
            </button>
        </ng-container>
    </sqx-modal-dialog>
</ng-container>

<div class="notification-container notification-container-bottom-right">
    <div class="alert alert-dismissible alert-{{notification.messageType}} shadowed" *ngFor="let notification of snapshot.notifications" (click)="close(notification)" @fade>
        <button type="button" class="btn-close" data-dismiss="alert" (close)="close(notification)"></button>

        <div class="shadow"></div>
        <div class="shadowed" [innerHTML]="notification.message | sqxTranslate | sqxMarkdown | sqxSafeHtml"></div>
    </div>
</div>

<ng-container *ngFor="let tooltip of snapshot.tooltips">
    <div class="tooltip2 tooltip2-{{tooltip.textPosition}}" 
        [sqxAnchoredTo]="tooltip.target" 
        [position]="tooltip.textPosition"
        [offsetY]="tooltip.offsetY"
        [offsetX]="tooltip.offsetX">
        {{tooltip.text | sqxTranslate}}

        <ng-container *ngIf="tooltip.shortcut">
            &nbsp;({{tooltip.shortcut}})
        </ng-container>
    </div>
</ng-container>