<div [formGroup]="form">
    <ng-container [ngSwitch]="field.properties.fieldType">
        <ng-container *ngSwitchCase="'Number'">
            <ng-container [ngSwitch]="field.rawProperties.editor">
                <ng-container *ngSwitchCase="'Input'">
                    <input class="form-control" type="number" [formControlName]="field.name" [placeholder]="field.displayPlaceholder">
                </ng-container>
                <ng-container *ngSwitchCase="'Stars'">
                    <sqx-stars [formControlName]="field.name" [maximumStars]="field.rawProperties.maxValue"></sqx-stars>
                </ng-container>
                <ng-container *ngSwitchCase="'Dropdown'">
                    <select class="form-select" [formControlName]="field.name">
                        <option [ngValue]="null"></option>
                        <option *ngFor="let value of field.rawProperties.allowedValues" [ngValue]="value">{{value}}</option>
                    </select>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'String'">
            <ng-container [ngSwitch]="field.rawProperties.editor">
                <ng-container *ngSwitchCase="'Input'">
                    <input class="form-control" type="text" [formControlName]="field.name" [placeholder]="field.displayPlaceholder">
                </ng-container>
                <ng-container *ngSwitchCase="'Slug'">
                    <input class="form-control" type="text" [formControlName]="field.name" [placeholder]="field.displayPlaceholder" sqxTransformInput="Slugify">
                </ng-container>
                <ng-container *ngSwitchCase="'Dropdown'">
                    <select class="form-select" [formControlName]="field.name">
                        <option [ngValue]="null"></option>
                        <option *ngFor="let value of field.rawProperties.allowedValues" [ngValue]="value">{{value}}</option>
                    </select>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'Boolean'">
            <ng-container [ngSwitch]="field.rawProperties.editor">
                <ng-container *ngSwitchCase="'Toggle'">
                    <sqx-toggle [formControlName]="field.name" [threeStates]="!field.properties.isRequired"></sqx-toggle>
                </ng-container>
                <ng-container *ngSwitchCase="'Checkbox'">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [formControlName]="field.name" id="{{uniqueId}}" sqxIndeterminateValue>
                        <label class="form-check-label" for="{{uniqueId}}"></label>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</div>