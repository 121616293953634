<div class="btn-group btn-group-{{size}}" *ngIf="isSmallMode">
    <button type="button" class="btn btn-outline-secondary" *ngFor="let supported of languages; trackBy: trackByLanguage" title="{{supported.englishName}}" [class.active]="supported === language" (click)="selectLanguage(supported)" tabindex="-1">
        {{supported.iso2Code}}
    </button>
</div>

<ng-container *ngIf="isLargeMode">
    <button type="button" class="btn btn-outline-secondary btn-{{size}} dropdown-toggle" title="{{language.englishName}}" (click)="dropdown.toggle()" #button tabindex="-1">
        {{language.iso2Code}}
    </button>

    <ng-container *sqxModal="dropdown;closeAlways:true">
        <sqx-dropdown-menu [sqxAnchoredTo]="button" [scrollY]="true">
            <table>
                <tbody>
                    <tr class="dropdown-item" *ngFor="let supported of languages; trackBy: trackByLanguage" [class.active]="supported === language" (click)="selectLanguage(supported)">
                        <td><strong class="iso-code">{{supported.iso2Code}}</strong></td>
                        <td>({{supported.englishName}})</td>
                    </tr>
                </tbody>
            </table>
        </sqx-dropdown-menu>
    </ng-container>
</ng-container>