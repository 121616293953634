<div class="selection">
    <div class="form-select" #input (click)="dropdown.show()">
        <div class="truncate">{{selection.item.folderName | sqxTranslate}}

            <span *ngIf="selectionPath">
                {{ 'common.in' | sqxTranslate }}&nbsp;./{{selectionPath}}
            </span>
        </div>
    </div>
</div> 

<div class="items-container">
    <ng-container *sqxModal="dropdown">
        <div class="control-dropdown" [sqxAnchoredTo]="input" [scrollY]="true" position="bottom-left">
            <sqx-asset-folder-dropdown-item 
                [appName]="appName"
                [node]="root"
                [nodeLevel]="0"
                (selectNode)="select($event)">
            </sqx-asset-folder-dropdown-item>
        </div>
    </ng-container>
</div>