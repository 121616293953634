<div class="events">
    <div class="event row g-0" *ngFor="let assetEvent of assetEvents | async; trackBy: trackByEvent">
        <div class="col-auto">
            <img class="user-picture" title="{{assetEvent.event.actor | sqxUserNameRef}}" [src]="assetEvent.event.actor | sqxUserPictureRef">
        </div>
        <div class="col ps-2">
            <div class="event-message">
                <span class="event-actor user-ref me-1" [title]="assetEvent.event.actor | sqxUserNameRef:null">{{assetEvent.event.actor | sqxUserNameRef:null}}</span> 
                <span [innerHTML]="assetEvent.event | sqxHistoryMessage"></span>
            </div>
    
            <div class="event-created">{{assetEvent.event.created | sqxFromNow}}</div>
    
            <ng-container *ngIf="assetEvent.canDownload">
                <a class="event-load force" [href]="asset | sqxAssetUrl:assetEvent.version" sqxExternalLink="noicon">{{ 'assets.downloadVersion' | sqxTranslate }}</a> 
            </ng-container>
        </div>
    </div>
</div>