<div class="form-container">
    <div class="form-control tags" tabindex="0" #form 
        (mousedown)="focusInput($event)"
        (focus)="focusInput($event)"
        [class.blank]="styleBlank" 
        [class.singleline]="singleLine"
        [class.readonly]="readonly"
        [class.suggested]="suggestionsSorted.length > 0"
        [class.multiline]="!singleLine"
        [class.focus]="snapshot.hasFocus" 
        [class.disabled]="snapshot.isDisabled"
        [class.dashed]="styleDashed && !(snapshot.items.length > 0)">
        <span class="item" *ngFor="let item of snapshot.items; let i = index" [class.disabled]="addInput.disabled">
            {{item}} <i class="icon-close" (click)="remove(i)"></i>
        </span>

        <input type="text" class="blank text-input" #input
            (blur)="markTouched()" (copy)="onCopy($event)" (cut)="onCut($event)" (focus)="focus()" (keydown)="onKeyDown($event)" (paste)="onPaste($event)"
            [name]="inputName"
            [placeholder]="placeholder | sqxTranslate"
            autocomplete="off" 
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            [formControl]="addInput">
    </div>

    <div class="btn btn-sm" (click)="openModal()" sqxStopClick *ngIf="!readonly && (allowOpen || suggestionsSorted.length > 0)">
        <i class="icon-caret-down"></i>
    </div>

    <ng-container *sqxModal="snapshot.suggestedItems.length > 0">
        <sqx-dropdown-menu class="control-dropdown" 
            [sqxAnchoredTo]="form"
            [adjustWidth]="true"
            [adjustHeight]="false"
            [scrollY]="true" 
            [style.minWidth]="dropdownWidth"
            position="bottom-left" #container>
            <div *ngFor="let item of snapshot.suggestedItems; let i = index" class="control-dropdown-item control-dropdown-item-selectable"
                [class.active]="i === snapshot.suggestedIndex"
                [class.separated]="itemSeparator"
                (mousedown)="selectValue(item)" 
                (mouseover)="selectIndex(i)"
                [sqxScrollActive]="i === snapshot.suggestedIndex"
                [sqxScrollContainer]="container.nativeElement">
                <ng-container>{{item}}</ng-container>
            </div>
        </sqx-dropdown-menu>
    </ng-container>

    <ng-container *sqxModal="suggestionsModal">
        <sqx-dropdown-menu class="control-dropdown suggestions-dropdown"
            [sqxAnchoredTo]="form"
            [adjustWidth]="false"
            [adjustHeight]="false"
            [scrollY]="true" 
            position="bottom-left">
            <div class="row">
                <div class=" col-6" *ngFor="let item of suggestionsSorted; let i = index">
                    <div class="form-check form-check">
                        <input class="form-check-input" type="checkbox" id="tag_{{i}}"
                            [ngModel]="isSelected(item)"
                            (ngModelChange)="toggleValue($event, item)"
                        />

                        <label class="form-check-label" for="tag_{{i}}" title="{{item.name}}" titlePosition="top-left">
                            <span class="truncate">{{item.name}}</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="text-decent" *ngIf="suggestionsSorted.length === 0">
                <ng-container *ngIf="suggestionsLoading; else notLoading">
                    <sqx-loader color="input"></sqx-loader>
                </ng-container>

                <ng-template #notLoading>
                    <small>{{suggestionsEmptyText | sqxTranslate}}</small>
                </ng-template>
            </div>
        </sqx-dropdown-menu>
    </ng-container>
</div>