<ng-container [ngSwitch]="field">
    <ng-container *ngSwitchCase="metaFields.id">
        <small class="truncate">{{content.id}}</small>
    </ng-container>
    <ng-container *ngSwitchCase="metaFields.created">
        <small class="truncate">{{content.created | sqxFromNow}}</small>
    </ng-container>
    <ng-container *ngSwitchCase="metaFields.createdByAvatar">
        <img class="user-picture" title="{{content.createdBy | sqxUserNameRef}}" [src]="content.createdBy | sqxUserPictureRef">
    </ng-container>
    <ng-container *ngSwitchCase="metaFields.createdByName">
        <small class="truncate">{{content.createdBy | sqxUserNameRef}}</small>
    </ng-container>
    <ng-container *ngSwitchCase="metaFields.lastModified">
        <small class="truncate">{{content.lastModified | sqxFromNow}}</small>
    </ng-container>
    <ng-container *ngSwitchCase="metaFields.lastModifiedByAvatar">
        <img class="user-picture" title="{{content.lastModifiedBy | sqxUserNameRef}}" [src]="content.lastModifiedBy | sqxUserPictureRef">
    </ng-container>
    <ng-container *ngSwitchCase="metaFields.lastModifiedByName">
        <small class="truncate">{{content.lastModifiedBy | sqxUserNameRef}}</small>
    </ng-container>
    <ng-container *ngSwitchCase="metaFields.status">
        <ng-container *ngIf="content.newStatus; else singleStatus">
            <div class="row g-0 status-grid">
                <div class="col">
                    <sqx-content-status [truncate]="true"
                        layout="text"
                        [status]="content.status"
                        [statusColor]="content.statusColor">
                    </sqx-content-status>
                </div>
                <div class="col-auto">
                    <i class="icon-caret-right"></i>
                </div>
                <div class="col">
                    <sqx-content-status [truncate]="true" 
                        layout="text"
                        [status]="content.newStatus"
                        [statusColor]="content.newStatusColor!"
                        [scheduled]="content.scheduleJob">
                    </sqx-content-status>
                </div>
            </div>
        </ng-container>

        <ng-template #singleStatus>
            <sqx-content-status [truncate]="true" 
                layout="text"
                [status]="content.status"
                [statusColor]="content.statusColor"
                [scheduled]="content.scheduleJob">
            </sqx-content-status>
        </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="metaFields.statusNext">
        <span class="truncate" *ngIf="content.scheduleJob">
            <sqx-content-status
                layout="text"
                [status]="content.scheduleJob.status"
                [statusColor]="content.scheduleJob.color">
            </sqx-content-status>
        
            {{ 'contents.scheduledAt' | sqxTranslate }}&nbsp;{{content.scheduleJob?.dueTime | sqxShortDate}}
        </span>
    </ng-container>
    <ng-container *ngSwitchCase="metaFields.statusColor">
        <ng-container *ngIf="content.newStatus; else singleStatus">
            <span class="text-nowrap">
                <sqx-content-status
                    [status]="content.status"
                    [statusColor]="content.statusColor">
                </sqx-content-status>
    
                <i class="icon-caret-right"></i>
    
                <sqx-content-status
                    [status]="content.newStatus"
                    [statusColor]="content.newStatusColor!"
                    [scheduled]="content.scheduleJob">
                </sqx-content-status>
            </span>
        </ng-container>

        <ng-template #singleStatus>    
            <sqx-content-status
                [status]="content.status"
                [statusColor]="content.statusColor"
                [scheduled]="content.scheduleJob">
            </sqx-content-status>
        </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="metaFields.version">
        <small class="truncate">{{content.version.value}}</small>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <ng-container *ngIf="field.rootField && isInlineEditable && patchAllowed && patchForm; else displayTemplate">
            <sqx-content-value-editor [form]="patchForm" [field]="field.rootField"></sqx-content-value-editor>
        </ng-container>

        <ng-template #displayTemplate>
            <sqx-content-value [value]="snapshot.formatted" [field]="field" [fields]="fields"></sqx-content-value>
        </ng-template>
    </ng-container>
</ng-container>