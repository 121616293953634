<sqx-modal-dialog (close)="emitComplete()" size="lg" [fullHeight]="true" [flexBody]="true" [hasTabs]="false">
    <ng-container title>
        <div class="row">
            <div class="col-selector">
                <select class="form-select" [ngModel]="schema" (ngModelChange)="selectSchema($event)">
                    <option *ngFor="let schema of schemas" [ngValue]="schema">
                        {{ 'contents.referencesSelectSchema' | sqxTranslate: { schema: schema.displayName } }}
                    </option>
                </select>
            </div>
        </div>
    </ng-container>

    <ng-container tabs>
        <div class="row gx-2 mt-3 mb-3">
            <ng-container *ngIf="schema">
                <div class="col-auto">
                    <button type="button" class="btn btn-text-secondary" (click)="reload()">
                        <i class="icon-reset"></i>
                    </button>
                </div>
                <div class="col">
                    <sqx-search-form formClass="form" placeholder="{{ 'contents.searchPlaceholder' | sqxTranslate }}" 
                        [language]="language"
                        [languages]="languages"
                        (queryChange)="search($event)"
                        [query]="contentsState.query | async"
                        [queryModel]="queryModel | async"
                        [statuses]="contentsState.statuses | async">
                    </sqx-search-form>
                </div>
                    
                <div class="col-auto" *ngIf="languages.length > 1">
                    <sqx-language-selector class="languages-buttons" [(language)]="language" [languages]="languages"></sqx-language-selector>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <ng-container content>
        <ng-container *ngIf="schema">
            <sqx-list-view [isLoading]="contentsState.isLoading | async" [syncedHeader]="true" [table]="true">
                <ng-container header>
                    <table class="table table-items table-fixed" [sqxContentListWidth]="schema.defaultReferenceFields" #header>
                        <thead>
                            <tr>
                                <th class="cell-select">
                                    <input type="checkbox" class="form-check" 
                                        [ngModel]="selectedAll" 
                                        (ngModelChange)="selectAll($event)">
                                </th>
                                <th sqxContentListCell [field]="metaFields.lastModifiedByAvatar">
                                    <sqx-content-list-header [language]="language" [field]="metaFields.lastModifiedByAvatar"></sqx-content-list-header>
                                </th>
                                <th *ngFor="let field of schema.defaultReferenceFields">
                                    <sqx-content-list-header
                                        [field]="field"
                                        [query]="(contentsState.query | async)!"
                                        (queryChange)="search($event)"
                                        [language]="language">
                                    </sqx-content-list-header>
                                </th>
                                <th sqxContentListCell [field]="metaFields.statusColor">
                                    <sqx-content-list-header [language]="language" [field]="metaFields.statusColor"></sqx-content-list-header>
                                </th>
                            </tr>
                        </thead>
                    </table>
                </ng-container>
    
                <ng-container content>
                    <div class="table-container">
                        <table class="table table-items table-fixed" [sqxContentListWidth]="schema.defaultReferenceFields" *ngIf="contentsState.contents | async; let contents" [sqxSyncWidth]="header">
                            <tbody *ngFor="let content of contents; trackBy: trackByContent"
                                [sqxContentSelectorItem]="content"
                                [language]="language"
                                [schema]="schema"
                                [selectable]="!isItemAlreadySelected(content)"
                                [selected]="isItemSelected(content)"
                                (selectedChange)="selectContent(content)">
                            </tbody>
                        </table>
                    </div>
                </ng-container>
    
                <ng-container footer>
                    <sqx-pager (loadTotal)="reloadTotal()" [paging]="contentsState.paging | async" (pagingChange)="contentsState.page($event)"></sqx-pager>
                </ng-container>
            </sqx-list-view>
        </ng-container>
    </ng-container>

    <ng-container footer>
        <button type="button" class="btn btn-text-secondary" (click)="emitComplete()">
            {{ 'common.cancel' | sqxTranslate }}
        </button>

        <button type="submit" class="btn btn-success" (click)="emitSelect()" [disabled]="selectionCount === 0 || selectionCount > maxItems">
            {{ 'contents.referencesLink' | sqxTranslate: { count: selectionCount } }}
        </button>
    </ng-container>
</sqx-modal-dialog>