<div class="comment row g-0">
    <div class="col-auto pe-2">
        <img class="user-picture" title="{{comment.user | sqxUserNameRef}}" [src]="comment.user | sqxUserPictureRef">
    </div>

    <ng-container *ngIf="!snapshot.isEditing; else editing">
        <div class="col col-text">
            <div class="comment-message">
                <div class="user-row">
                    <div class="user-ref" [title]="comment.user | sqxUserNameRef:null">{{comment.user | sqxUserNameRef:null}}</div>
                </div>
    
                <div [innerHTML]="comment.text | sqxMarkdown | sqxSafeHtml"></div>
    
                <div class="comment-created text-muted">
                    <ng-container *ngIf="canFollow && comment.url">
                        <a [routerLink]="comment.url">{{ 'comments.follow' | sqxTranslate }}</a>&nbsp;
                    </ng-container>
    
                    {{comment.time | sqxFromNow}} 
                </div>
            </div>
        </div>

        <div class="actions">
            <button *ngIf="isEditable && canEdit" type="button" class="btn btn-sm btn-text-secondary" (click)="startEdit()">
                <i class="icon-pencil"></i>
            </button>
        
            <button *ngIf="isDeletable || canDelete" type="button" class="btn btn-sm btn-text-danger"
                (sqxConfirmClick)="delete()"
                confirmTitle="i18n:comments.deleteConfirmTitle"
                confirmText="i18n:comments.deleteConfirmText"
                confirmRememberKey="deleteComment"
                [confirmRequired]="confirmDelete">
                <i class="icon-bin2"></i>
            </button>
        </div>
    </ng-container>

    <ng-template #editing>
        <div class="col">
            <form (ngSubmit)="update()">
                <textarea class="form-control mb-1" name="{{comment.id}}" sqxFocusOnInit [(ngModel)]="editingText" 
                    [mention]="$any(mentionUsers)"
                    [mentionConfig]="mentionConfig"
                    (keydown)="updateWhenEnter($event)">
                </textarea>
    
                <div>
                    <button type="button" class="btn btn-sm btn-text-secondary me-1" (click)="cancelEdit()">
                        {{ 'common.cancel' | sqxTranslate }}
                    </button>

                    <button type="submit" class="btn btn-sm btn-primary">
                        <i class="icon-enter"></i> {{ 'common.save' | sqxTranslate }}
                    </button>
                </div>
            </form>
        </div>
    </ng-template>
</div>