<div #container (sqxResized)="updateContainerWidth($event.width)">
    <div class="form-check" *ngFor="let value of tagValues; trackBy: trackByValue"
        [class.form-check-block]="!snapshot.isSingleline"
        [class.form-check-inline]="snapshot.isSingleline">
        <input class="form-check-input" type="checkbox" id="{{controlId}}_{{value}}"
            (blur)="callTouched()"
            (change)="check($event.target!['checked']!, value)" 
            [checked]="isChecked(value)"
            [disabled]="snapshot.isDisabled">
    
        <label class="form-check-label" for="{{controlId}}_{{value}}">{{value.name}}</label>
    </div>
</div>