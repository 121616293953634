<ng-container *ngIf="events">
    <div *ngFor="let event of events; trackBy: trackByEvent" class="event row g-0">
        <div class="col-auto">
            <img class="user-picture" title="{{event.actor | sqxUserNameRef}}" [src]="event.actor | sqxUserPictureRef" />
        </div>
        <div class="col ps-2 event-right">
            <div class="event-message">
                <span class="user-ref" [title]="event.actor | sqxUserNameRef:null">{{event.actor | sqxUserNameRef:null}}</span>
                <span [innerHTML]="event | sqxHistoryMessage"></span>
            </div>
            <div class="event-created">{{event.created | sqxFromNow}}</div>
        </div>
    </div>
</ng-container>