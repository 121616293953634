<tr (click)="toggle()">
    <td class="cell-select" sqxStopClick>
        <input type="checkbox" class="form-check"
            [disabled]="!selectable"
            [ngModel]="selected || !selectable"
            (ngModelChange)="select($event)"
        />
    </td>

    <td sqxContentListCell [field]="metaFields.lastModifiedByAvatar">
        <sqx-content-list-field [language]="language" [field]="metaFields.lastModifiedByAvatar" [content]="content"></sqx-content-list-field>
    </td>

    <td *ngFor="let field of schema.defaultReferenceFields">
        <sqx-content-list-field [field]="field" [content]="content" [language]="language"></sqx-content-list-field>
    </td>

    <td sqxContentListCell [field]="metaFields.statusColor">
        <sqx-content-list-field [language]="language" [field]="metaFields.statusColor" [content]="content"></sqx-content-list-field>
    </td>
</tr>
<tr class="spacer"></tr>